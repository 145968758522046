import 'alpinejs';
import Muuri from 'muuri';
import fitvids from 'fitvids';

// *  *  1. Cookies  *  *  //



// * *  End cookie  *  *  //


if (document.getElementById('myvid') != null) {
    var mv = null;
    mv = document.getElementById('myvid');

    fitvids()
}

// *  *  4. INDEX  Grid controls  *  *  //

var gridA ;

if( document.getElementsByClassName('isogrid') !== null) {



  try {

      gridA = new Muuri('.isogrid', {
      containerClass: 'isogrid',
      showEasing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      fillGaps: true

    });

      window.addEventListener('load', function () {

      gridA.refreshItems().layout();
      // document.body.classList.add('images-loaded');



    });

  }

  catch(err) {
    console.log(err + " = Murri err");

  }
}

// *  *   7. form control  *  *  //




